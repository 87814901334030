.form_wrapper{
    display: grid;
    align-content: center;
    width: 100vw;
    height: 100dvh;
    max-width: 50rem;
    margin: auto;
    padding: 3rem;
}

.error{
    color: #f90a0a;
    text-align: left;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
}

@media (orientation: portrait) {
    .form_wrapper{
        width: 100%;
        padding: 1rem;
    }
    
}