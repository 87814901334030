.btn_create {
    display    : flex;
    align-items: center;
    gap        : 1.5rem;
}

.btn_create svg {
    width : 3rem;
    height: 3rem;
}

.btn_create span {
    font-weight: 500;
    font-size  : 2rem;
    line-height: 2.5rem;
    color      : var(--primary-color);
    margin-top : -0.4rem;
}

@media (orientation: portrait) {
    .btn_create span {
        display: none;
    }
    .btn_create svg {
        width: 2.4rem;
        height: 2.4rem;
    }
}