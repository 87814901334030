.crud_view_content {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    border-left    : 1px solid rgba(153, 153, 153, 0.5);
    padding-left   : 9.5rem;
}

.crud_view_content h1 {
    color      : var(--gray-2);
    font-size    : 1.8rem;
    line-height  : 2.4rem;
    margin-bottom: 1.2rem;
}

.crud_view_content>*:not(:last-child) {
    margin-bottom: 3.2rem;
}

.crud_view_content p {
    font-weight: 500;
    color      : var(--gray-5);
    word-break : break-word;
}

.crud_view_content img {
    width    : 100%;
    max-width: 50%;
}