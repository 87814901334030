
.sidebar_nav {
    height         : 100%;
    display        : grid;
    grid-template-rows: 80% 20%;

}

.nav_items {
    height            : 100%;
    display        : grid;
    overflow-y        : auto;
    -ms-overflow-style: none;
    scrollbar-width   : none;
    grid-auto-rows: 60px;
}

.nav_items::-webkit-scrollbar {
    display: none;
}

.common_items {
    display       : grid;
    align-items: end;
    border-top: 1px solid white;
    padding: 3% 0;

}


.sidebar_nav button {
    display      : grid;
    grid-template-columns: 15% 85%;
    align-items  : center;
    color        : rgba(255, 255, 255, 0.7);
    border-radius: .8rem;
    padding      : 6% 10%;
}

.sidebar:not(.expanded) .sidebar_nav button {
    display: inline-flex;
}

.sidebar_nav button.active {
    background-color: var(--primary-color);
    color           : var(--gray-7);
}

.sidebar_nav button.active:hover {
    background-color: var(--primary-color);
    color           : var(--gray-7);
}

.sidebar_nav button svg {
    width : 2.4rem;
    height: 2.4rem;
}

.sidebar_nav button.active svg path {
    stroke: var(--gray-7);
}

.sidebar_nav button.active:hover svg path {
    stroke: var(--gray-7);
}

.sidebar_nav button:hover svg path {
    stroke: var(--primary-color);
}

.sidebar_nav button:hover {
    color: var(--primary-color);
}

.sidebar_nav button>span {
    font-weight: 500;
    font-size  : 1.3rem;
    line-height: 1.5rem;
    padding-left: 1rem;
}