.custom_editor {
    margin-bottom: 2.4rem;
    color: #ffff;
}

/* .ck-editor__editable_inline {
    min-height: 40rem;
} */
.ck-content {
    margin-bottom: 2.4rem;
    min-height: 20rem;
    color: black;

}