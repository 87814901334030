.form_top_content{
    margin-bottom: 4rem;
}
.form_top_content h1{
    color:var(--white) ;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 6px;


}
.form_top_content p{
    color: var(--gray-3);
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

}
.form_top_content p:hover{
    color: var(--primary-color);
}
.register_account{
    display: flex;
    align-items: center;
    gap: 8px;
}

.register_account button{
    color: var(--primary-color);
}