.navbar {
    display: none;
}


@media (orientation: portrait) {
    .navbar {
        background-color: var(--black);
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding        : 2rem 2.4rem;
    }

    .navbar .logo_wrapper {
        max-width: 14.2rem;
        margin   : 0 auto;
    }

    .navbar .user_box {
        width        : 2.8rem;
        height       : 2.8rem;
        border-radius: 50%;
    }

    .navbar .user_box img {
        height         : inherit;
        object-fit     : cover;
        object-position: center center;
    }
}