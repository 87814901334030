@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tourney&display=swap');
@import url('./css/crud/crudDashboard.css');
@import url('./css/crud/crudView.css');

:root {
  --primary-color: #f4f4f4;
}

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  font-size  : 1.8rem;
  line-height: 2.4rem;
}

.main {
  width           : 100vw;
  height          : 100vh;
  display         : grid;
  background-color: var(--gray-9);
  overflow        : hidden;
  transition: all 350ms ease-in-out;
}

.main.employee {
  grid-template-columns: 5% 95%;
}

.main.employee.expanded {
  grid-template-columns: 15% 85%;
}

.main.auth {
  place-items: center;
}

.error_message{
  font-size: 11px;
  color: red;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.filter_select {
  padding: 10px 30px 10px 10px;
  border-radius: 10px;
  /* min-width: 150px; */
  background: rgba(0, 0, 0, 0.3);
  color: var(--gray-3);
  font-weight: 700;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.filter_select option{
  color: white;
  background: var(--gray-3);
  font-size: 16px;
}

.product-name {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
}
@media (orientation: portrait) {
  .main {
    display: block;
  }
}
