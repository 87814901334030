
.sidebar {
   height: 100vh;
    display: grid;
    background-color: var(--black);
    place-items: center;
}
.sidebar_wrapper{
    height: 95%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 7.5% 85% 7.5%;
    
}
.sidebar_wrapper:not(.sidebar_wrapper_expanded){
    width: 60%;
}
.sidebar_wrapper.sidebar_wrapper_expanded{
    width: 80%;
}
@media (orientation: portrait) {
    .sidebar {
        width    : 100%;
        position : fixed;
        top: 0;
        transform: translateX(-100%);
        transition: transform .3s ease-in-out;
        z-index: 2;
    }

    .sidebar.show {
        transform: translateX(0);
    }
    .sidebar_wrapper.sidebar_wrapper_expanded{
        width: 90%;
        height: 95dvh;
    }
}