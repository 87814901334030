.user_wrapper {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.06);
  display: grid;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: end;
}

.user {
  display: grid;
  place-items: center;

}
.user.expanded {
  grid-template-columns: 30% 70%;
}
/* // .user .thumb {
//   width: 50%;
//   height: 50%;
// } */

.user img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
  aspect-ratio: 1/1;
}

.user h6,
.user span {
  width: 100%;
  font-size: 1rem;
  line-height: 2rem;
}

.user h6 {
  font-weight: 700;
  color: var(--gray-1);
}

.user span {
  font-weight: 400;
  color: var(--gray-7);
}
