.back_btn {
    background-color: var(--primary-color);
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

.back_btn:hover {
    color: white;
    background-color: #000;
    border: 1px solid #fff;
}