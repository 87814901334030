/* // .sidebar_nav .item:not(:last-child) {
//     margin-bottom: 3%;
// } */

.sidebar_nav a {
    display      : grid;
    grid-template-columns: 15% 85%;
    align-items  : center;
    color        : rgba(255, 255, 255, 0.7);
    border-radius: .8rem;
    padding      : 8px;
}

.sidebar:not(.expanded) .sidebar_nav a {
    display: inline-flex;

}

.sidebar_nav a.active  {
    background-color: var(--primary-color);
    color           : var(--gray-7);
}

.sidebar_nav a.active:hover  {
    background-color: var(--primary-color);
    color           : var(--gray-7);
}

.sidebar_nav a svg  {
    width: 2.4rem;
    height:2.4rem;
}
.sidebar_nav a.active svg path {
    stroke           : var(--gray-7);
}

.sidebar_nav a.active:hover svg path {
    stroke           : var(--gray-7);
}

.sidebar_nav a:hover svg path {
    stroke           : var(--primary-color);
}

.sidebar_nav a:hover {
    color           : var(--primary-color);
}

.sidebar_nav a>span {
    font-weight: 500;
    font-size  : 1.3rem;
    line-height: 1.5rem;
    margin-left: 10%;
}

@media (orientation: portrait) {
    .sidebar_nav a {
        display      : grid;
        grid-template-columns: 10% 90%;
        align-items  : center;
        color        : rgba(255, 255, 255, 0.7);
        border-radius: .8rem;
        padding      : 5%;
    }
}