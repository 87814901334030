* {
    padding   : 0;
    margin    : 0;
    box-sizing: border-box;
  }
  
  :root {
    --black : #000;
    --white : #FFFFFF;
    --gray-1: #E6E6E6;
    --gray-2: #CCCCCC;
    --gray-3: #B3B3B3;
    --gray-4: #999999;
    --gray-5: #808080;
    --gray-6: #666666;
    --gray-7: #4D4D4D;
    --gray-8: #333333;
    --gray-9: #1A1A1A;
  
    --bright-red: #eb5757;
    --dark-red  : #660101;
  }
  
  html {
    font-size      : 62.5%;
    scroll-behavior: smooth;
  }
  
  body {
    position               : relative;
    overflow-x             : hidden;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  ul,
  ol {
    list-style: none;
  }
  
  span {
    display: inline-block;
  }
  
  a {
    text-decoration: none;
  }
  
  svg,
  img {
    vertical-align: middle;
  }
  
  button {
    border          : 0;
    background-color: transparent;
    cursor          : pointer;
  }
  
  input,
  textarea,
  select {
    border: 0;
  }
  
  input:focus-visible,
  textarea:focus-visible,
  select:focus-visible,
  button:focus-visible {
    outline: none;
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
    margin     : 0;
    font-family: inherit;
    font-size  : inherit;
    line-height: inherit;
  }
  
  .w_100 {
    width: 100%;
  }
  
  @media (max-width: 991.98px) {
    button {
        cursor: auto;
    }
  }

  .css-t3ipsp-control,
.css-13cymwt-control{
    border-radius: 5px !important;
    background-color: #1A1A1A !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    color: var(--gray-4) !important;
    border: 1px solid var(--gray-6) !important;
    margin-bottom: 1.5rem;
    width: 100%;
    height: 50px;
}
.css-1dimb5e-singleValue{
    color: var(--gray-4) !important;
}
.css-1nmdiq5-menu{
    background-color: #D4D0D0 !important;
    color: #000 !important;
}