.screen_holder_wrapper{
    display: grid;
    place-items: center;
}

.employee_holder {
    height: 95dvh;
    width: 90%;
    /*  display: grid;
     grid-template-rows: 10% 90%; */
}


@media (orientation: portrait) {
    .screen_holder {
        height: calc(100% - 7.4rem);
        padding: 3rem 2.4rem 2.5rem;
    }
    
}